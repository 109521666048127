<template>
    <v-card outlined>
        <v-card-text>
            <v-row wrap ro align="center">
                <v-col cols="12">
                    <form autocomplete="nope" @submit.prevent="postData">
                        <v-container grid-list-md fluid>
                            <v-row dense align="center">
                                <v-col cols="12" sm="12">
                                    <v-autocomplete dense v-model="servicio" v-validate="'required'" label="Producto"
                                        :items="customProducts" data-vv-as="marca"
                                        :error-messages="errors.collect('servicio')" data-vv-name="servicio" required
                                        item-text="rename" item-value="id" return-object>
                                        <template v-slot:item="data">
                                            <v-chip small color="secondaryTheme" class="caption px-2" dark>{{
                                                data.item.marca ? data.item.marca : data.item.category }}-{{
                                                data.item.internal_id }}</v-chip>
                                            <v-chip v-if="data.item.idCodigo" small color="green" class="caption px-2"
                                                dark>{{ data.item.idCodigo }}</v-chip>
                                            <span class="caption">{{ data.item.rename }} |
                                                {{ isformatNumber(data.item, 'price') }}
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-text-field dense v-model="servicio.unidades" label="Unidades" @keypress="isNumber" />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field v-if="options" dense v-model="servicio.comision"
                                        label="Comisión POS (+)" />
                                </v-col>
                                <v-col v-if="user.idBussines != 1 && options == true" cols="12" sm="3">
                                    <v-text-field dense v-model="servicio.descuentos" label="Descuentos (-)" />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field dense v-model="itemTotalProduct.subTotal" filled rounded label="Sub total"
                                        readonly />
                                </v-col>
                                <v-col cols="12" sm="12" class="text-center">
                                    <v-row>
                                        <v-col v-if="itemTotalProduct.subTotal != 0" cols="12" sm="3"><v-btn block
                                                color="secondaryTheme" dark type="submit">agregar</v-btn></v-col>
                                        <v-col cols="12" sm="4" v-if="options"><v-btn
                                                :disabled="datos.atributos.wtspProductos" block color="green"
                                                @click="sendSmsWhatsapp" dark>Enviar wtsp productos</v-btn></v-col>
                                        <v-col cols="12" sm="3" v-if="options"><v-btn
                                                :disabled="datos.atributos.wtspService" block color="green"
                                                @click="sendServiceWhataspp" dark>Enviar wtsp Servicios</v-btn></v-col>
                                    </v-row>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-container>
                    </form>
                </v-col>
                <v-col cols="12" class="grey lighten-5">
                    <v-data-table multi-sort :headers="headers" :items="_productos" class="elevation-1" :items-per-page="20"
                        item-key="timestamp">
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="green"
                                        @click="upItem(item)">mdi-swap-vertical</v-icon>
                                </template>
                                <span>SUBIR</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" v-bind="attrs" v-on="on" small
                                        @click="deleteItem(item)">mdi-delete</v-icon>
                                </template>
                                <span>ELIMINAR</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { formatNumber } from '@/utils/genericUtils'
import { parse } from 'date-fns'
import { sleep } from '@/utils/genericUtils'
export default {
    filters: {},
    props: ['lista', 'service', 'notOptions'],
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            msgWhatsapp: '',
            descriptionLimit: 60,
            ProductosFilter: [],
            isLoading: false,
            model: null,
            servicio: DefaultForm.formSelectServicio(),
            listaServicios: [],
            search: null,
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'Descripción', value: 'description' },
                { text: 'Tipo', value: 'unit_type_id' },                
                { text: 'Codigo', value: 'internal_id' },
                { text: 'Marca', value: 'marca' },
                { text: 'Unidades', value: 'unidades' },
                { text: 'Moneda', value: 'tipoMoneda' },
                { text: 'Precio', value: '_subTotal' },
                { text: 'Comision', value: 'comision' },
                { text: 'Descuento', value: 'descuentos' },
                { text: 'SubTotal', value: 'subTotal' }
            ]
        }
    },
    watch: {
        _selectItem(val) {
            //console.log('val', val)
        },
        productos: {
            deep: true,
            handler(newList) {
                if (newList.length === 0) {
                    this.listaServicios = []
                } else {
                    this.listaServicios = newList
                }
            }
        }
    },
    computed: {
        ...mapState('utilities', ['tipoDocumento', 'tipoComprobante', 'tipoBancoDestino']),
        options() {
            return !this.notOptions
        },
        idTipoMoneda() {
            return this.servicio.idTipoMoneda || 1
        },
        ...mapState('ventas', ['datos', 'modalReserva', 'productos']),
        ...mapState('category', ['genericUtils']),
        ...mapState('users', ['user', 'listaUser']),
        ...mapState('productos', ['listaProductos', 'dataproducto']),
        ...mapGetters('productos', ['customProducts']),
        idProducto() {
            return this.servicio.id
        },
        _selectItem() {
            //console.log('this._selectItem', this.servicio)
            return this.servicio
        },
        fields() {
            if (!this.model) return []

            return Object.keys(this.model).map((key) => {
                return {
                    key,
                    value: this.model[key] || 'n/a'
                }
            })
        },

        _productos() {
            let _listaPro = []
            try {
                //console.log('this.productos', this.productos)
                if (this.productos && this.productos.length > 0) {
                    //console.log('here')
                    this.productos.map((x) => {
                        _listaPro.push({
                            ...x,
                            tipoMoneda: x.idTipoMoneda === 1 ? 'S/' : '$',
                            _subTotal: x.price || x.price.toFixed(2)
                        })
                    }).filter({})
                }
            } catch (error) {
                //console.log('err', error)
            }
            return _listaPro
        },
        itemTotalProduct() {
            let val = {
                price: 0,
                subTotal: 0
            }
            const { descuentos, comision, price, unidades } = this.servicio
            //console.log('this.servicio', this.servicio)
            if (this.servicio) {
                val.subTotal = (
                    this._parseFloat(price) * this._parseFloat(unidades) +
                    this._parseFloat(comision) * this._parseFloat(unidades) -
                    this._parseFloat(descuentos)
                ).toFixed(2)
                val.price = this._parseFloat(price) + this._parseFloat(comision)
            }
            //console.log('val', val)
            //console.log('hola')
            return {
                price: val.price,
                subTotal: val.subTotal
            }
        },
    },
    async mounted() {
        await this.$store.dispatch('productos/products', { active: 1 })
    },
    methods: {
        async sendServiceWhataspp() {
            let listaProductos = ''
            for (const item of this.productos) {
                listaProductos = listaProductos.concat(`• *${item.description}* ; Cantidad(s) ${item.unidades}`)
            }
            const colaborador = this.listaUser.find((x) => x.id == this.datos.idColaborador)?.nombres ?? this.user.nombres + ' ' + this.user.apellidos
            const requiredParams = {
                telefono: `51${this.datos.celularContacto || this.datos.telefono}`,
                colaborador,
                idVenta: this.datos.id,
                listaProductos
            }
            const params = await this.$store.dispatch('utilities/botWhatsappVerify', requiredParams)
            if (params.status) {
                await this.$store.dispatch('ventas/botWhatsapp', {
                    idTemplate: 2,
                    ...requiredParams
                })
                await this.$store.dispatch('ventas/patchVenta', { ...this.datos, atributos: { ...this.datos.atributos, wtspService: true } })
            }
            const text = params.status ? 'Mensaje enviado correctamente' : `ERROR  -  ${params.empty} vacio`
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text,
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },
        async sendSmsWhatsapp() {
            let listaProductos = ''
            for (const iterator of this.productos) {
                let ProductFacturacion = await this.$store.dispatch('ventas/obtenerProducto', {
                    id: iterator.id,
                    idSede: this.user.idSede
                })
                const linkProduct = ProductFacturacion.data.attributes
                    ? JSON.parse(ProductFacturacion.data.attributes).find((x) => x.attribute_type_id == '8000') || null
                    : null
                listaProductos = listaProductos.concat(
                    `• *${iterator.description}* ; Cantidades ${iterator.unidades} -- ${linkProduct ? linkProduct.value : ' No Registrado'}`
                )
            }
            const colaborador = this.listaUser.find((x) => x.id == this.datos.idColaborador)?.nombres ?? this.user.nombres + ' ' + this.user.apellidos
            const requiredParams = {
                telefono: `51${this.datos.celularContacto || this.datos.telefono}`,
                colaborador,
                idVenta: this.datos.id,
                listaProductos
            }
            const params = await this.$store.dispatch('utilities/botWhatsappVerify', requiredParams)
            if (params.status) {
                await this.$store.dispatch('ventas/botWhatsapp', {
                    idTemplate: 1,
                    ...requiredParams
                })
                await this.$store.dispatch('ventas/patchVenta', { ...this.datos, atributos: { ...this.datos.atributos, wtspProductos: true } })
            }
            const text = params.status ? 'Mensaje enviado correctamente' : `ERROR  -  ${params.empty} vacio`
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text,
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },


        async searchProduct(val) {
            const Productos = await this.$store.dispatch('productos/buscarProducto', this.servicio)
            //console.log('Productos', Productos)
            return
        },

        isformatNumber(item, name) {
            const value = item[name]
            return formatNumber(value, item.idTipoMoneda)
        },
        _parseFloat(val) {
            return parseFloat(val) || 0
        },

        deleteItem(item) {
            // //console.log(item);
            let lista = this.productos
            lista = lista.filter((v) => v.timestamp != item.timestamp)
            this.$store.commit('ventas/UPDATE_PRODUCTOS', lista)
            //this.listaServicios = lista
        },
        upItem(newItem) {
            const position = this.productos.findIndex((x) => x.timestamp == newItem.timestamp)
            //console.log('position ', position)
            const lista = Object.assign([], this.productos)
            if (position >= 1) {
                const oldItem = { ...lista[position - 1], timestamp: Date.now() }
                ////console.log('lista ', lista)
                lista.splice(position, 1, oldItem)
                ////console.log('lista 1', lista)
                lista.splice(position - 1, 1, newItem)
                ////console.log('lista ', lista)
                //colors.splice(2, 0, 'purple');

                this.$store.commit('ventas/UPDATE_PRODUCTOS', lista)
            }
        },
        postData() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    //console.log('this.servicio', this.servicio)
                    //console.log('this.itemTotalProduct', this.itemTotalProduct)
                    let lista = []
                    const listaServicios = []
                    const servicio = {
                        ...this.servicio,
                        priceOrigen: this.servicio.sale_unit_price,
                        idTipoMoneda: this.servicio.idTipoMoneda ? this.servicio.idTipoMoneda : 1,
                        price: this.servicio.price,
                        subTotal: this.itemTotalProduct.subTotal,
                        timestamp: Date.now()
                    }
                    //console.log('servicio ', servicio)
                    const tipoCambio = servicio.idTipoMoneda === 1 ? 1 : null
                    this.$store.commit('ventas/UPDATE_DATOS', {
                        idTipoMoneda: servicio.idTipoMoneda,
                        tipoCambio
                    })
                    //console.log('service')
                    listaServicios.push(servicio)
                    lista = this.productos.concat(listaServicios)
                    //console.log('lista', lista)
                    this.$store.commit('ventas/UPDATE_PRODUCTOS', lista)
                    this.servicio = DefaultForm.formSelectServicio()
                }
            })
        }
    }
}
</script>
<style></style>
